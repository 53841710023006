import { capitalise } from "@wisr/common";
import { Accordion, Card } from "@wisr/react-ui";
import React from "react";
import { analyticsClickEvent } from "../../../shared/analytics/analytics.utils";
import {
  EQUIFAX_CORRECTIONS,
  EXPERIAN_CORRECTIONS,
} from "../../../shared/url.consts";
import { CapitalisedScoreProviders } from "../../../types/credit";
import { SCORE_PROVIDER } from "../../../widgets/credit/score/score-credit-widget.consts";
import { NavLink } from "../../../widgets/shared/nav-link/nav-link.component";
import { CORRECTIONS_NUMBER } from "../credit-insights.constants";

export const SpotError = ({
  type,
  provider,
}: {
  type: "accordion" | "card";
  provider: any;
}) => {
  const capitalProvider: CapitalisedScoreProviders | undefined = capitalise(
    provider ?? ""
  ) as CapitalisedScoreProviders | undefined;
  const isEquifax = provider === SCORE_PROVIDER.EQUIFAX;
  const helpLink = isEquifax ? EQUIFAX_CORRECTIONS : EXPERIAN_CORRECTIONS;

  const helpNumber = isEquifax
    ? CORRECTIONS_NUMBER.EQUIFAX
    : CORRECTIONS_NUMBER.EXPERIAN;

  if (!provider) {
    return null;
  }

  const SPOT_ERROR_TEXT: React.ReactElement = (
    <>
      <p>
        It&apos;s important to check that the information on your credit file is
        correct, otherwise you might experience complications when applying for
        credit in the future.
      </p>
      <br />
      <h4>What should I do if I find an error?</h4>
      <p>
        If something doesn&apos;t look right,{" "}
        <strong>contact the credit bureau directly</strong>. They are obligated
        to investigate and correct the error.
      </p>
      <p>
        Unfortunately, we can&apos;t fix errors - we can only help you spot
        them.
      </p>
      <br />
      <h4>How do I contact {capitalProvider}?</h4>
      <p>
        You can submit a correction request using the{" "}
        <strong>{capitalProvider} Corrections Portal</strong>. Or you can
        contact them on {helpNumber}. They&apos;re friendly we promise!
      </p>
      <NavLink
        to={helpLink}
        external
        size="small"
        onClick={() => {
          capitalProvider &&
            analyticsClickEvent(`${capitalProvider} correction process`, {
              "Page name": `${capitalProvider} credit report`,
            });
        }}
      >
        Contact {capitalProvider}
      </NavLink>
    </>
  );

  if (type === "accordion") {
    return (
      <Accordion title="Spot an error?" startOpen={false} alignArrow="right">
        {SPOT_ERROR_TEXT}
      </Accordion>
    );
  }

  if (type === "card") {
    return (
      <Card>
        <h3>Spot an error?</h3>
        {SPOT_ERROR_TEXT}
      </Card>
    );
  }

  return null;
};
