import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";
export interface SwiperProps {
  slides?: JSX.Element[];
  spaceBetween?: number;
  slidesPerView?: number;
}

export const SwiperComponent: React.FC<SwiperProps> = ({
  slides,
  spaceBetween = 20,
  slidesPerView = 2,
}) => {
  if (!slides) return null;

  return (
    <>
      <Swiper
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        breakpoints={{
          768: {
            slidesPerView: 2,
            slidesPerGroup: 1,
            slidesPerColumnFill: "row",
            slidesPerColumn: slides.length,
            noSwiping: true,
            noSwipingClass: "swiper-slide",
          },
        }}
      >
        {slides.map((slide: JSX.Element, index) => {
          return <SwiperSlide key={index}>{slide}</SwiperSlide>;
        })}
      </Swiper>
    </>
  );
};
