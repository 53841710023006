import React, { useState } from "react";
import { Card } from "@wisr/react-ui";
import { isDefined, useLoadingStatus } from "@wisr/common";
import { GETSCORE_CREDIT_LOADING } from "../score/score-credit-widget.epic";
import { generateSlides } from "./contributing-factors-widget.utils";
import { SwiperComponent } from "../../../shared/swiper/swiper";
import { isNotEmpty } from "../../../shared/common.utils";
import { SkeletonLoaderWidget } from "../score/skeleton-loader-widget";
import { useGetContributingFactors } from "../score/score-credit-widget.hooks";
import ContributingFactorsStyles from "./contributing-factors-widget.scss";
import { useFlags } from "launchdarkly-react-client-sdk";
import { analyticsCustomEvent } from "../../../shared/analytics/analytics.utils";

export const ContributingFactorsWidget: React.FC = () => {
  const [scoreLoading] = useLoadingStatus(GETSCORE_CREDIT_LOADING);

  const contributingFactors = useGetContributingFactors();
  const [positiveSlides, setPositiveSlides] = useState<JSX.Element[]>([]);
  const [negativeSlides, setNegativeSlides] = useState<JSX.Element[]>([]);
  const { showKcfDetails } = useFlags();
  const componentIsReady = !scoreLoading && isDefined(contributingFactors);

  React.useEffect(() => {
    if (isNotEmpty(contributingFactors)) {
      const positiveFactors = contributingFactors.filter(
        (slide) => slide.kcfCharacteristic === "Positive"
      );
      const negativeFactors = contributingFactors.filter(
        (slide) => slide.kcfCharacteristic === "Negative"
      );
      setPositiveSlides(generateSlides(positiveFactors));
      setNegativeSlides(generateSlides(negativeFactors));
    }
  }, [contributingFactors]);

  React.useEffect(() => {
    if (
      componentIsReady &&
      (positiveSlides.length > 0 || negativeSlides.length > 0)
    ) {
      analyticsCustomEvent("Viewed kcf widget", {
        "Positive factors": positiveSlides.length,
        "Negative factors": negativeSlides.length,
      });
    }
  }, [componentIsReady, positiveSlides.length, negativeSlides.length]);

  if (
    window.location.pathname.includes("experian") ||
    !showKcfDetails ||
    (contributingFactors && !contributingFactors.length)
  ) {
    return null;
  }

  if (!componentIsReady) {
    return <SkeletonLoaderWidget variation={""} />;
  }

  return (
    <div className="contributing-factors">
      <style jsx>{ContributingFactorsStyles}</style>
      <Card>
        <h4 className="title">
          What&apos;s impacting your score?{" "}
          <span>({contributingFactors.length})</span>
        </h4>
        <p className="small-copy">
          Knowing what impacts your credit score can help you improve it over
          time.
        </p>

        {!!positiveSlides.length && (
          <div className="postive-container">
            <p className="small-copy bold">What you&apos;re doing well</p>
            <p className="small-copy">These factors strengthen your score.</p>

            <div className="slider-container">
              <SwiperComponent
                slides={positiveSlides}
                spaceBetween={10}
                slidesPerView={1.15}
              />
            </div>
          </div>
        )}
        {!!positiveSlides.length && !!negativeSlides.length && <hr />}
        {!!negativeSlides.length && (
          <div className="negative-container">
            <p className="small-copy bold">What you can improve</p>
            <p className="small-copy">
              These factors can negatively impact your score.
            </p>
            <SwiperComponent
              slides={negativeSlides}
              spaceBetween={10}
              slidesPerView={1.15}
            />
          </div>
        )}
      </Card>
    </div>
  );
};
