import React from "react";
import { BadgeComponent } from "../../../../shared/badge/badge.component";
import ContributingFactorsStyles from "../contributing-factors-widget.scss";
import { getKcfIcon } from "./contributing-factors-slide.utils";
import { ContributingFactorDetails } from "../../../../types/score-credit-widget";

interface ContributingFactorsSlideProps {
  slide: ContributingFactorDetails;
}

export const ContributingFactorsSlide: React.FC<
  ContributingFactorsSlideProps
> = ({ slide }) => {
  const icon = getKcfIcon(slide.id);

  return (
    <div className="contributing-factors-slide">
      <style jsx>{ContributingFactorsStyles}</style>
      <div
        className={`slide-card ${
          slide.kcfCharacteristic === "Negative" && "grey"
        }`}
      >
        <div className="slide-card-icon-column">{icon}</div>
        <div className="slide-card-copy-column">
          <h6 className="slide-card-heading">{slide.title}</h6>
          <p>{slide.description}</p>
        </div>
        {slide.isNew && (
          <div className="badge-wrapper">
            <BadgeComponent badge="alert">
              <span>NEW</span>
            </BadgeComponent>
          </div>
        )}
      </div>
    </div>
  );
};
