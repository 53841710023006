import React from "react";
import fifteenDays from "../../../../assets/images/contributing-factors/15-29-days.svg";
import thirtyDays from "../../../../assets/images/contributing-factors/30-plus-days.svg";
import accountsOverdue from "../../../../assets/images/contributing-factors/accounts-overdue.svg";
import bnplEnquiries from "../../../../assets/images/contributing-factors/bnpl-enquiries.svg";
import closedCards from "../../../../assets/images/contributing-factors/closed-cards.svg";
import creditApplication from "../../../../assets/images/contributing-factors/credit-applications.svg";
import creditEnquiries from "../../../../assets/images/contributing-factors/credit-enquiries.svg";
import employment from "../../../../assets/images/contributing-factors/employment.svg";
import genericKcf from "../../../../assets/images/contributing-factors/generic-kcf.svg";
import frequentApplications from "../../../../assets/images/contributing-factors/frequent-applications.svg";
import residential from "../../../../assets/images/contributing-factors/residential.svg";
import shortTermEnquiries from "../../../../assets/images/contributing-factors/short-term-enquiries.svg";
import strongHistory from "../../../../assets/images/contributing-factors/strong-history.svg";
import unsecuredLoans from "../../../../assets/images/contributing-factors/unsecured-loans.svg";

export const getKcfIcon = (id: string) => {
  switch (id) {
    case "KCF-003":
    case "KCF-004":
    case "KCF-006":
      return <img src={creditApplication} />;
    case "KCF-301":
      return <img src={thirtyDays} />;
    case "KCF-302":
      return <img src={fifteenDays} />;
    case "KCF-303":
    case "KCF-304":
      return <img src={accountsOverdue} />;
    case "KCF-305":
      return <img src={closedCards} />;
    case "KCF-306":
    case "KCF-317":
    case "KCF-318":
    case "KCF-344":
    case "KCF-345":
    case "KCF-346":
      return <img src={residential} />;
    case "KCF-307":
      return <img src={unsecuredLoans} />;
    case "KCF-309":
      return <img src={shortTermEnquiries} />;
    case "KCF-310":
      return <img src={bnplEnquiries} />;
    case "KCF-311":
    case "KCF-312":
      return <img src={frequentApplications} />;
    case "KCF-313":
    case "KCF-314":
    case "KCF-319":
    case "KCF-320":
    case "KCF-329":
    case "KCF-330":
    case "KCF-335":
    case "KCF-336":
      return <img src={creditEnquiries} />;
    case "KCF-326":
    case "KCF-327":
    case "KCF-337":
    case "KCF-338":
      return <img src={employment} />;
    case "KCF-328":
      return <img src={strongHistory} />;
    default:
      return <img src={genericKcf} />;
  }
};
