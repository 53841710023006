import { ContributingFactorsSlide } from "./contributing-factors-slide/contributing-factors-slide";
import React from "react";
import { SlideDataProps } from "../../../types/contributing-factors-widget";

export const generateSlides = (slideData: SlideDataProps[]) => {
  const slideArr: JSX.Element[] = [];

  slideData.forEach((slide) => {
    slideArr.push(<ContributingFactorsSlide slide={slide} />);
  });

  return slideArr;
};
