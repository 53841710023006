export const EnquiriesDescription =
  "Credit enquiries count the number of times you’ve applied for credit and show your appetite for financial products and also the frequency which you apply. All enquiries that the credit bureau knows about you will be listed below, whether you accepted the offer of finance or not.";
export const AccountsDescription =
  "The total number of accounts you’ve ever opened. Accounts show how much credit risk you are exposed to, and how often you change or acquire new financial products. Proving your ability to not only get credit, but that you are responsibly repaying it can play a role in moving your credit score. Some financial institutions also could look at your payment history when deciding to lend to you.";
export const ImpairmentsDescription =
  "Credit impairments include defaults, bankruptcies and judgements. Major credit events like these on your financial journey can often substantially impact your credit score and ability to get credit in the future.";
export const AgeDescription =
  "The duration that the bureau has known about your financial activity can play some part in their assessing of your financial behaviour and credit risk. As you prove your reliability over time, or not, your credit score can move.";
export const IdentitiesDescription =
  "While most of us have one, sometimes there can be more here - sometimes because of middle names or name changes throughout life.";
export const EmployersDescription =
  "Any known employer details, recent or otherwise, that the bureau are aware of.";
export const AddressesDescription =
  "As you move through life, your current and historical addresses form a trail of information that bureaus can use things such as reliability and permanance.";

export enum HistoryCodesEquifax {
  PAYMENT_PENDING = "p",
  PAYMENT_ONTIME = "0",
  PAYMENT_OVERDUE_29DAYS = "1",
  PAYMENT_OVERDUE_59DAYS = "2",
  PAYMENT_OVERDUE_89DAYS = "3",
  PAYMENT_OVERDUE_119DAYS = "4",
  PAYMENT_OVERDUE_149DAYS = "5",
  PAYMENT_OVERDUE_179DAYS = "6",
  PAYMENT_OVERDUE_MAXDAYS = "x",
  PAYMENT_NOTREPORTED = "r",
  ACCOUNT_CLOSED = "c",
  ACCOUNT_NOTASSOCIATED = "a",
  ACCOUNT_TRANSFERRED = "t",
}

export const SUCCESS_PAYMENT_EQUIFAX: string[] = [
  HistoryCodesEquifax.PAYMENT_ONTIME,
];

export const FAILED_PAYMENT_EQUIFAX: string[] = [
  HistoryCodesEquifax.PAYMENT_OVERDUE_29DAYS,
  HistoryCodesEquifax.PAYMENT_OVERDUE_59DAYS,
  HistoryCodesEquifax.PAYMENT_OVERDUE_89DAYS,
  HistoryCodesEquifax.PAYMENT_OVERDUE_119DAYS,
  HistoryCodesEquifax.PAYMENT_OVERDUE_149DAYS,
  HistoryCodesEquifax.PAYMENT_OVERDUE_179DAYS,
  HistoryCodesEquifax.PAYMENT_OVERDUE_MAXDAYS,
];

export const UNKNOWN_PAYMENT_EQUIFAX: string[] = [
  HistoryCodesEquifax.PAYMENT_NOTREPORTED,
  HistoryCodesEquifax.ACCOUNT_CLOSED,
  HistoryCodesEquifax.ACCOUNT_NOTASSOCIATED,
  HistoryCodesEquifax.ACCOUNT_TRANSFERRED,
];

// Experian has no pending payments
export enum HistoryCodesExperian {
  PAYMENT_ONTIME = "0",
  PAYMENT_OVERDUE_29DAYS = "1",
  PAYMENT_OVERDUE_59DAYS = "2",
  PAYMENT_OVERDUE_89DAYS = "3",
  PAYMENT_OVERDUE_119DAYS = "4",
  PAYMENT_OVERDUE_149DAYS = "5",
  PAYMENT_OVERDUE_179DAYS = "6",
  PAYMENT_OVERDUE_MAXDAYS = "x",
  PAYMENT_DEFAULT_ONTIME = "c",
  PAYMENT_DEFAULT_ACTIVE = "d",
  PAYMENT_DEFAULT_OVERDUE = "p",
  ACCOUNT_DEFAULT_SETTLED = "s",
  PAYMENT_NOTREPORTED = "u",
}

export const SUCCESS_PAYMENT_EXPERIAN: string[] = [
  HistoryCodesExperian.PAYMENT_ONTIME,
  HistoryCodesExperian.PAYMENT_DEFAULT_ONTIME,
];

export const FAILED_PAYMENT_EXPERIAN: string[] = [
  HistoryCodesExperian.PAYMENT_OVERDUE_29DAYS,
  HistoryCodesExperian.PAYMENT_OVERDUE_59DAYS,
  HistoryCodesExperian.PAYMENT_OVERDUE_89DAYS,
  HistoryCodesExperian.PAYMENT_OVERDUE_119DAYS,
  HistoryCodesExperian.PAYMENT_OVERDUE_149DAYS,
  HistoryCodesExperian.PAYMENT_OVERDUE_179DAYS,
  HistoryCodesExperian.PAYMENT_OVERDUE_MAXDAYS,
  HistoryCodesExperian.PAYMENT_DEFAULT_OVERDUE,
];

export const UNKNOWN_PAYMENT_EXPERIAN: string[] = [
  HistoryCodesExperian.PAYMENT_NOTREPORTED,
  HistoryCodesExperian.ACCOUNT_DEFAULT_SETTLED,
  HistoryCodesExperian.PAYMENT_DEFAULT_ACTIVE,
];

export const CORRECTIONS_NUMBER: { [key: string]: string } = {
  EQUIFAX: "13 8332",
  EXPERIAN: "1300 783 684",
};

export const SCROLL_TRIGGER = -350;
export const SCROLL_DEBOUNCE = 250;
