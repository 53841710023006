import { capitalise, monthsToYears } from "@wisr/common";
import { Button } from "@wisr/react-ui";
import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { GaugeGraphChartComponent } from "../../../../../charts/gauge-graph/gauge-graph-chart.component";
import {
  convertTimeZone,
  stringToDate,
} from "../../../../../shared/date.utils";
import { WidgetComponent } from "../../../../widget.component";
import { CreditScore } from "../../../../../types/score-credit-widget";
import { ScoreProviders } from "../../../../../types/credit";
import {
  equifaxBands,
  equifaxOneBands,
  experianBands,
  gaugeColours,
  SCORE_PROVIDER,
} from "../../score-credit-widget.consts";
import detailedStyle from "./detailed-single-score-credit-widget.scss";
import scoreStyle from "../single-score-credit-widget.scss";
import scoreMovementStyle from "../score-credit-movement/score-credit-movement.scss";
import {
  getLatestDate,
  getScoreState,
  getSelectedScore,
} from "../../score-credit-widget.utils";
import { Link } from "gatsby";
import { analyticsClickEvent } from "../../../../../shared/analytics/analytics.utils";
import {
  HighlightComponent,
  HighlightItem,
} from "../../../../shared/highlight/highlight.component";
import { CreditInsightsPageResponse } from "../../../../../insights/credit/credit-insights";
import { tryFormatPaymentHistory } from "../../../../../shared/credit/credit.utils";
import { GaugeBands } from "../../../../../types/graph";

interface DetailedSingleScoreProps {
  provider: ScoreProviders;
  creditScore?: CreditScore;
  detailedInsights: CreditInsightsPageResponse;
}

export const DetailedSingleScoreCreditWidget: React.FC<
  DetailedSingleScoreProps
> = ({ provider, creditScore, detailedInsights }) => {
  const { showBillsPaidOnTime } = useFlags();
  if (!creditScore) {
    return null;
  }

  const validUpdate = creditScore.runDetails.length > 0;
  const lastUpdate: string | Date = convertTimeZone(
    getLatestDate(creditScore.runDetails, true)
  );
  const nextUpdate: string | Date = convertTimeZone(
    getLatestDate(creditScore.runDetails)
  );
  const selectedEquifaxBands =
    creditScore.oneScoreComprehensive && creditScore.oneScoreComprehensive !== 0
      ? equifaxOneBands
      : equifaxBands;

  const bands: GaugeBands[] =
    provider === SCORE_PROVIDER.EQUIFAX ? selectedEquifaxBands : experianBands;
  const selectedScore = getSelectedScore(creditScore);
  const noScore = creditScore.creditScoreDisplay !== "Has credit score";
  const highlightData: HighlightItem[] = [
    {
      title: detailedInsights.enquiries.length.toString(),
      description:
        detailedInsights.enquiries.length === 1
          ? "Credit enquiry"
          : "Credit enquiries",
      type: "small",
    },
    {
      title: detailedInsights.accounts.length.toString(),
      description:
        detailedInsights.accounts.length === 1
          ? "Total account"
          : "Total accounts",
      type: "small",
    },
    {
      title: showBillsPaidOnTime
        ? tryFormatPaymentHistory(creditScore.paymentHistory)
        : "n/a",
      description: "Payments on time",
      type: "small",
      id: `${provider}-payments-on-time`,
    },
    {
      title: monthsToYears(creditScore.ageOfFileInMonths, true).toString(),
      description: "Credit file age",
      type: "small",
    },
    {
      title: detailedInsights.employers.length.toString(),
      description:
        detailedInsights.employers.length === 1 ? "Employer" : "Employers",
      type: "small",
    },
    {
      title: detailedInsights.addresses.length.toString(),
      description:
        detailedInsights.addresses.length === 1 ? "Address" : "Addresses",
      type: "small",
    },
  ];

  if (noScore) {
    return (
      <WidgetComponent
        widgetName="credit-score-single-detailed"
        widgetType="credit"
      >
        <style jsx>{scoreStyle}</style>
        <style jsx>{detailedStyle}</style>
        <div className="widget-container">
          <div className="widget-title">
            <h1>{capitalise(provider)} credit report</h1>
          </div>
          <div className="widget-data">
            <div className={`widget-graph ${provider}-provider`}>
              <GaugeGraphChartComponent
                gaugeData={{
                  gaugeData: "?",
                  gaugeLimit: creditScore.maxScore,
                  gaugeLabel: provider,
                  gaugeDialColour: gaugeColours[provider],
                  customLabel: creditScore.creditScoreDisplay,
                }}
                gaugeBands={bands}
              />
            </div>
            <div className="widget-content">
              <p>
                Your information isn&apos;t matching any profiles with the
                credit Bureau. Check that the profile information provided is
                accurate and try again.
              </p>
            </div>
          </div>
          <Button button="secondary" theme="navy">
            <Link
              to={`/credit/my-account`}
              onClick={() => analyticsClickEvent(`Review my information`)}
            >
              Review my information
            </Link>
          </Button>
        </div>
      </WidgetComponent>
    );
  }

  return (
    <WidgetComponent widgetName="credit-score-single" widgetType="credit">
      <div className={`widget-container`}>
        <style jsx>{scoreStyle}</style>
        <style jsx>{detailedStyle}</style>
        <style jsx>{scoreMovementStyle}</style>
        <div className="widget-title">
          <h1>{capitalise(provider)} credit report</h1>
        </div>
        <div className="widget-data">
          <div className={`widget-graph ${provider}-provider`}>
            <GaugeGraphChartComponent
              gaugeData={{
                gaugeData: selectedScore,
                gaugeLimit: creditScore.maxScore,
                gaugeLabel: provider,
                gaugeDialColour: gaugeColours[provider],
              }}
              gaugeBands={bands}
            />
            <div
              className={`score-movement ${getScoreState(
                creditScore.deltaScore
              )}`}
            >
              {creditScore.deltaScore} points
            </div>
            <div className="subtitle">
              Last {validUpdate && stringToDate(lastUpdate, "two-digits")}{" "}
              &#8226; Updates {stringToDate(nextUpdate, "two-digits")}
            </div>
          </div>
          <div className="widget-content">
            <HighlightComponent variation="boxed" list={highlightData} />
          </div>
        </div>
      </div>
    </WidgetComponent>
  );
};
