import { monthsToYears, toCurrency, toRate } from "@wisr/common";
import { Scroller } from "@wisr/react-ui";
import React from "react";
import { stringToDate } from "../../shared/date.utils";
import { ScoreProviders } from "../../types/credit";
import {
  HistoryCodesEquifax,
  HistoryCodesExperian,
  SUCCESS_PAYMENT_EXPERIAN,
  UNKNOWN_PAYMENT_EQUIFAX,
  UNKNOWN_PAYMENT_EXPERIAN,
} from "./credit-insights.constants";
import {
  InsightsAccounts,
  InsightsAddresses,
  InsightsAge,
  InsightsBankruptcies,
  InsightsDefaults,
  InsightsEmployers,
  InsightsEnquiries,
  InsightsIdentities,
  InsightsJudgements,
  InsightsRepaymentHistory,
} from "./credit-insights";
import { SCORE_PROVIDER } from "../../widgets/credit/score/score-credit-widget.consts";

export const calculatePaymentHistoryPercentage = (
  repaymentHistory: InsightsRepaymentHistory[],
  provider: ScoreProviders
) => {
  if (repaymentHistory.length > 0) {
    let totalSuccessRepaymentHistory: InsightsRepaymentHistory[];
    let totalAvailablePayment: InsightsRepaymentHistory[];

    if (provider === SCORE_PROVIDER.EQUIFAX) {
      totalSuccessRepaymentHistory = repaymentHistory.filter((item) => {
        return (
          item.statusCode?.toLowerCase() === HistoryCodesEquifax.PAYMENT_ONTIME
        );
      });
      // Only get known payments
      totalAvailablePayment = repaymentHistory.filter((item) => {
        const statusCode = item.statusCode ? item.statusCode.toLowerCase() : "";
        return (
          statusCode !== "" &&
          statusCode !== HistoryCodesEquifax.PAYMENT_PENDING &&
          !UNKNOWN_PAYMENT_EQUIFAX.includes(statusCode)
        );
      });
    } else {
      totalSuccessRepaymentHistory = repaymentHistory.filter((item) => {
        return SUCCESS_PAYMENT_EXPERIAN.includes(
          item.statusCode?.toLowerCase() ?? ""
        );
      });
      totalAvailablePayment = repaymentHistory.filter((item) => {
        const statusCode = item.statusCode ? item.statusCode.toLowerCase() : "";
        return (
          statusCode !== "" && !UNKNOWN_PAYMENT_EXPERIAN.includes(statusCode)
        );
      });
    }

    return totalAvailablePayment.length // Dont want to divide by 0
      ? toRate(
          (totalSuccessRepaymentHistory.length / totalAvailablePayment.length) *
            100
        )
      : "0.00%";
  }

  return "-";
};

export const convertEnquiresData = (enquires: InsightsEnquiries[]) => {
  return enquires.map((enquiry) => {
    return [
      {
        label: "Name",
        value: enquiry.name,
      },
      {
        label: "Amount",
        value: toCurrency(enquiry.value),
      },
      {
        label: "Type",
        value: enquiry.type,
      },
      {
        label: "Enquiry date",
        value: stringToDate(enquiry.date),
      },
    ];
  });
};
export const convertAccountsData = (
  accounts: InsightsAccounts[],
  provider: ScoreProviders
) => {
  return accounts.map((account) => {
    return [
      {
        label: "Name",
        value: account.name,
      },
      {
        label: "Status",
        value: account.status,
      },
      {
        label: "Open date",
        value: stringToDate(account.openDate),
      },
      {
        label: "Closed date",
        value: account.closeDate ? stringToDate(account.closeDate) : "n/a",
      },
      {
        label: "Limit",
        value: account.latestLimit
          ? toCurrency(account.latestLimit)
          : account.limit
          ? toCurrency(account.limit)
          : "n/a",
      },
      {
        label: "Last payment date",
        value: account.lastPaymentMade
          ? stringToDate(account.lastPaymentMade)
          : "n/a",
      },
      {
        label: "24 month repayment history",
        value: `${calculatePaymentHistoryPercentage(
          account.repaymentHistory,
          provider
        ).toString()} on-time`,
      },
      {
        value: repaymentHistoryToScroller(account.repaymentHistory, provider),
        fullWidth: true,
      },
    ];
  });
};
export const convertIdentitiesData = (identities: InsightsIdentities[]) => {
  return identities.map((identity) => {
    return [
      {
        label: "First name",
        value: identity.firstName,
      },
      {
        label: "Middle name",
        value: identity.middleName,
      },
      {
        label: "Last name",
        value: identity.lastName,
      },
      {
        label: "Date of birth",
        value: stringToDate(identity.dob),
      },
      {
        label: "First seen",
        value: stringToDate(identity.firstSeenDate),
      },
      {
        label: "Last seen",
        value: stringToDate(identity.lastSeenDate),
      },
    ];
  });
};
export const convertEmployerData = (employers: InsightsEmployers[]) => {
  return employers.map((employer) => {
    return [
      {
        label: "Employer name",
        value: employer.employerName,
      },
      {
        label: "First seen",
        value: stringToDate(employer.startDate),
      },
      {
        label: "Last seen",
        value: stringToDate(employer.endDate),
      },
    ];
  });
};
export const convertAddressesData = (addresses: InsightsAddresses[]) => {
  return addresses.map((address) => {
    return [
      {
        label: "Street address",
        value: address.streetAddress,
      },
      {
        label: "First seen",
        value: stringToDate(address.firstSeen),
      },
      {
        label: "Last seen",
        value: stringToDate(address.lastSeen),
      },
    ];
  });
};
export const convertCreditAgeData = (age: InsightsAge) => {
  return [
    [
      {
        label: "Open date",
        value: stringToDate(age.openDate),
      },
      {
        label: "Age",
        value: monthsToYears(age.ageOfFileMonths),
      },
    ],
  ];
};

export const convertImpairmentData = (
  defaults: InsightsDefaults[],
  judgements: InsightsJudgements[],
  bankrupcies: InsightsBankruptcies[]
) => {
  const defaultData = convertDefaultsData(defaults);
  const judgementData = convertJudgementData(judgements);
  const bankruptcyData = convertBankruptcyData(bankrupcies);
  return [...defaultData, ...judgementData, ...bankruptcyData];
};

export const convertDefaultsData = (defaults: InsightsDefaults[]) => {
  return defaults.map((data) => {
    return [
      {
        label: "Impairment",
        value: "Default",
      },
      {
        label: "Name",
        value: data.name,
      },
      {
        label: "Type",
        value: data.type,
      },
      {
        label: "Description",
        value: data.description,
      },
      {
        label: "Status",
        value: data.status,
      },
      {
        label: "Date",
        value: stringToDate(data.dateOfDefault),
      },
    ];
  });
};

export const convertJudgementData = (judgements: InsightsJudgements[]) => {
  return judgements.map((judgement) => {
    return [
      {
        label: "Impairment",
        value: "Judgement",
      },
      {
        label: "Plaintiff ",
        value: judgement.plaintiff,
      },
      {
        label: "Type",
        value: judgement.type,
      },
      {
        label: "Jurisdiction name",
        value: judgement.jurisdictionName,
      },
      {
        label: "Date",
        value: judgement.date ? stringToDate(judgement.date) : "n/a",
      },
      {
        label: "Amount",
        value: toCurrency(judgement.amount),
      },
      {
        label: "Status",
        value: judgement.status,
      },
      {
        label: "Status date",
        value: judgement.statusDate
          ? stringToDate(judgement.statusDate)
          : "n/a",
      },
    ];
  });
};

export const convertBankruptcyData = (bankrupcies: InsightsBankruptcies[]) => {
  return bankrupcies.map((bankruptcy) => {
    return [
      {
        label: "Impairment",
        value: "Bankruptcy",
      },
      {
        label: "Trustee title/Surname/Business name",
        value: bankruptcy.title,
      },
      {
        label: "Open date",
        value: stringToDate(bankruptcy.openData),
      },
      {
        label: "Close date",
        value: bankruptcy.closeDate ? stringToDate(bankruptcy.closeDate) : "",
      },
      {
        label: "Status",
        value: bankruptcy.status,
      },
      {
        label: "Status date",
        value: stringToDate(bankruptcy.statusDate),
      },
    ];
  });
};

export const renderIcon = (str: string, option: ScoreProviders) => {
  if (option === SCORE_PROVIDER.EXPERIAN) {
    switch (str) {
      case HistoryCodesExperian.PAYMENT_DEFAULT_ONTIME:
      case HistoryCodesExperian.PAYMENT_ONTIME:
        return "approved";
      //FAILED_PAYMENT_EXPERIAN
      case HistoryCodesExperian.PAYMENT_OVERDUE_29DAYS:
      case HistoryCodesExperian.PAYMENT_OVERDUE_59DAYS:
      case HistoryCodesExperian.PAYMENT_OVERDUE_89DAYS:
      case HistoryCodesExperian.PAYMENT_OVERDUE_119DAYS:
      case HistoryCodesExperian.PAYMENT_OVERDUE_149DAYS:
      case HistoryCodesExperian.PAYMENT_OVERDUE_179DAYS:
      case HistoryCodesExperian.PAYMENT_OVERDUE_MAXDAYS:
      case HistoryCodesExperian.PAYMENT_DEFAULT_OVERDUE:
        return "reject";
      default:
        return "other";
    }
  } else {
    switch (str) {
      case HistoryCodesEquifax.PAYMENT_ONTIME:
        return "approved";
      case HistoryCodesEquifax.PAYMENT_PENDING:
        return "pending";
      //FAILED_PAYMENT_EQUIFAX
      case HistoryCodesEquifax.PAYMENT_OVERDUE_29DAYS:
      case HistoryCodesEquifax.PAYMENT_OVERDUE_59DAYS:
      case HistoryCodesEquifax.PAYMENT_OVERDUE_89DAYS:
      case HistoryCodesEquifax.PAYMENT_OVERDUE_119DAYS:
      case HistoryCodesEquifax.PAYMENT_OVERDUE_149DAYS:
      case HistoryCodesEquifax.PAYMENT_OVERDUE_179DAYS:
      case HistoryCodesEquifax.PAYMENT_OVERDUE_MAXDAYS:
        return "reject";
      default:
        return "other";
    }
  }
};

export const repaymentHistoryToScroller = (
  repayments: InsightsRepaymentHistory[],
  provider: ScoreProviders
) => {
  const paymentsData = repayments.map((pay) => {
    return {
      icon: renderIcon(pay.statusCode?.toLowerCase() || "", provider),
      value: stringToDate(pay.date, "month-year"),
    };
  });
  return <Scroller scrollItems={paymentsData} />;
};
