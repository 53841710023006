import { useSelector } from "react-redux";
import { ScoreProviders } from "../../../generated/graphql-types";
import { AppStore } from "../../store";
import { CreditInsightsPageResponse } from "./credit-insights";

export const useGetInsightsPage = (provider?: ScoreProviders | null) => {
  return useSelector<AppStore, CreditInsightsPageResponse | undefined>(
    (store) => (provider ? store.insightsCreditPageState[provider] : undefined)
  );
};
