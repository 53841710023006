/* istanbul ignore file - graphql/page query cant be reliably tested */

import { graphql } from "gatsby";
import { CreditInsightsComponent } from "../../../insights/credit/credit-insights.component";

export default CreditInsightsComponent;

export const query = graphql`
  query CreditInsights($provider: ScoreProviders) {
    provider(provider: { eq: $provider }) {
      provider
    }
  }
`;
