import React from "react";
import refinanceStyle from "./refinance-offer-widget.scss";
import { analyticsCustomEvent } from "../../../../shared/analytics/analytics.utils";
import { NavLink } from "../../../shared/nav-link/nav-link.component";
import { QUOTE_FORM } from "../../../../shared/url.consts";
import { RATE_ESTIMATE_BID } from "../../../../shared/bid.consts";
import { useGetUser } from "../../../../user/user.hooks";
import { getRefinanceImage } from "./refinance-offer-widget.utils";
import { useGetMoneyMoment } from "../../../credit/score/score-credit-widget.hooks";

export const RefiCreditInsightsWidget = () => {
  const user = useGetUser();
  const moneyMoment = useGetMoneyMoment();
  const icon = getRefinanceImage(moneyMoment?.infographic ?? "");

  React.useEffect(() => {
    if (moneyMoment?.offerCode) {
      analyticsCustomEvent("presentedDashboardOffer", {
        "Dashboard offer": "Rate estimate",
        Location: "Dashboard credit insights",
        Personalisation: moneyMoment.offerCode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moneyMoment]);

  return (
    <>
      <style jsx>{refinanceStyle}</style>
      <div
        className="refinance-widget-wrap credit-insights"
        data-background={moneyMoment?.color}
      >
        <div className="image">{icon}</div>
        <div className="content">
          <div>
            <h2>{moneyMoment?.title}</h2>
            <p className="description">{moneyMoment?.description}</p>
          </div>

          <div className="link-wrapper">
            <NavLink
              className="view-rate-estimate-link"
              to={`${QUOTE_FORM}?wcToken=${user.access_token}&Product=Dashboard&bid=${RATE_ESTIMATE_BID}`}
              size="medium"
              external={true}
              onClick={() => {
                analyticsCustomEvent("clickedWidgetOffer", {
                  "Dashboard offer": "Rate estimate",
                  Location: "Dashboard credit insights",
                  Personalisation: moneyMoment?.offerCode,
                });
              }}
            >
              {moneyMoment?.linkTitle}
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};
