import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";

import {
  capitalise,
  isBrowser,
  mergeArrays,
  monthsToYears,
  useLoadingStatus,
} from "@wisr/common";
import {
  Accordion,
  Card,
  HorizontalTableList,
  SkeletonLoader,
} from "@wisr/react-ui";

import { CreditInsightsQuery } from "../../../generated/graphql-types";
import { CapitalisedScoreProviders } from "../../types/credit";
import { GatsbyPageProps } from "../../types/gatsby";
import { insightsCreditPageGetAction } from "./credit-insights.actions";
import { scoreCreditGetAction } from "../../widgets/credit/score/score-credit-widget.actions";
import { GET_INSIGHTS_CREDIT_PAGE_LOADING } from "./credit-insights.epic";
import { GETSCORE_CREDIT_LOADING } from "../../widgets/credit/score/score-credit-widget.epic";
import { useGetInsightsPage } from "./credit-insights.hooks";
import {
  useGetRefiEligible,
  useGetScores,
} from "../../widgets/credit/score/score-credit-widget.hooks";
import { PageWrapperComponent } from "../../layout/page-wrapper/page-wrapper-layout.component";
import { DetailedSingleScoreCreditWidget } from "../../widgets/credit/score/single/detailed/detailed-single-score-credit-widget";
import { ContributingFactorsWidget } from "../../widgets/credit/contributing-factors/contributing-factors-widget";
import { ErrorWidgetComponent } from "../../widgets/shared/error/error-widget.component";
import { WidgetComponent } from "../../widgets/widget.component";
import { EVENT } from "../../shared/analytics/analytics.consts";
import { BRAND_WEBSITE } from "../../shared/url.consts";
import {
  AccountsDescription,
  AddressesDescription,
  AgeDescription,
  EmployersDescription,
  EnquiriesDescription,
  IdentitiesDescription,
  ImpairmentsDescription,
  SCROLL_TRIGGER,
  SCROLL_DEBOUNCE,
} from "./credit-insights.constants";
import {
  convertAccountsData,
  convertAddressesData,
  convertCreditAgeData,
  convertEmployerData,
  convertEnquiresData,
  convertIdentitiesData,
  convertImpairmentData,
} from "./credit-insights.utils";
import {
  analyticsCustomEvent,
  analyticsViewCreditPageEvent,
} from "../../shared/analytics/analytics.utils";
import { isNotEmpty } from "../../shared/common.utils";
import pageStyle from "./credit-insights.scss";
import { RefiCreditInsightsWidget } from "../../widgets/borrowing/rate-estimate/refinance-offer/refi-credit-insights-widget";
import { SpotError } from "./spot-error/spot-error";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

type Props = GatsbyPageProps<void, CreditInsightsQuery>;

export const CreditInsightsComponent: React.FC<Props> = ({ data }) => {
  const [showOnScroll, setShowOnScroll] = useState(false);
  const { provider } = data?.provider || {};
  const [insightsPageLoading, insightsPageloadingError] = useLoadingStatus(
    GET_INSIGHTS_CREDIT_PAGE_LOADING
  );
  const [scoresLoading, scoresLoadingError] = useLoadingStatus(
    GETSCORE_CREDIT_LOADING
  );
  const insightsPageReady = !insightsPageLoading && !scoresLoading;
  const dispatch = useDispatch();
  const refiEligible = useGetRefiEligible();
  const insightsData = useGetInsightsPage(provider);
  const creditData = useGetScores();
  const isFirstUpdate = useRef(true);
  const capitalProvider: CapitalisedScoreProviders | undefined = capitalise(
    provider ?? ""
  ) as CapitalisedScoreProviders | undefined;
  const urlParams = isBrowser() && new URLSearchParams(window.location.search);
  const insightParam = urlParams && urlParams.get("insight");
  const SCROLL_DELAY = 500;
  const scrollRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (capitalProvider) {
      analyticsViewCreditPageEvent(`${capitalProvider} insights`);
    }
  }, [capitalProvider]);

  React.useEffect(() => {
    if (provider && !insightsData) {
      dispatch(insightsCreditPageGetAction(provider));
    }
  }, [provider, dispatch, insightsData]);

  React.useEffect(() => {
    if (!isNotEmpty(creditData)) {
      dispatch(scoreCreditGetAction());
    }
  }, [creditData, dispatch]);

  React.useEffect(() => {
    if (creditData?.creditScoreSummaryList && isFirstUpdate.current) {
      isFirstUpdate.current = false; // stop retriggering this block after firing once

      const providerInsight = creditData.creditScoreSummaryList.filter(
        (item) => item.provider === provider
      )[0];

      if (providerInsight) {
        const eventData = {
          Provider: capitalise(providerInsight.provider),
          "Credit enquiries": providerInsight.numberOfEnquiries,
          "Total accounts": providerInsight.numberOfAccounts,
          "Payments on time": providerInsight.paymentHistory,
          "Age of file": monthsToYears(providerInsight.ageOfFileInMonths),
        };
        analyticsCustomEvent(EVENT.WIDGETLOAD, {
          "Widget name": "Credit score insights",
          ...eventData,
        });
      }
    }
  }, [creditData, dispatch, provider]);

  React.useEffect(() => {
    setTimeout(() => {
      if (scrollRef.current && insightParam && insightsPageReady) {
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, SCROLL_DELAY);
  }, [insightParam, insightsPageReady]);

  useScrollPosition(
    /* istanbul ignore next */
    ({ currPos }) => {
      const isShow = currPos.y < SCROLL_TRIGGER;
      if (isShow !== showOnScroll) setShowOnScroll(isShow);
    },
    [showOnScroll],
    undefined,
    false,
    SCROLL_DEBOUNCE
  );

  if (!data || !provider || !isBrowser()) {
    return null;
  }

  if (insightsPageloadingError || scoresLoadingError) {
    return (
      <WidgetComponent widgetName="credit-insights-error" widgetType="credit">
        <ErrorWidgetComponent />
      </WidgetComponent>
    );
  }

  if (insightsPageReady) {
    return (
      <PageWrapperComponent size="half">
        <style jsx>{pageStyle}</style>
        <div className="prefix page-data">
          {insightsData && creditData?.creditScoreSummaryList && (
            <DetailedSingleScoreCreditWidget
              provider={provider}
              creditScore={creditData.creditScoreSummaryList.find(
                (item) => item.provider === provider
              )}
              detailedInsights={insightsData}
            />
          )}
          <div ref={"contributing-factors" === insightParam ? scrollRef : null}>
            <ContributingFactorsWidget />
          </div>
          <div className="insights-container">
            <Card>
              <Accordion
                title={`Credit enquiries`}
                dataCount={`(${insightsData?.enquiries.length || 0})`}
                subtitle={"High impact to credit scores"}
                description={EnquiriesDescription}
                startOpen
                alignArrow="right"
              >
                {insightsData?.enquiries && (
                  <HorizontalTableList
                    listItems={convertEnquiresData(insightsData.enquiries)}
                    tooltip={InsightsTooltip}
                    rowLimit={2}
                  />
                )}
              </Accordion>
            </Card>
            <Card>
              <Accordion
                title={`Total accounts`}
                dataCount={`(${insightsData?.accounts.length || 0})`}
                subtitle={"High impact to credit scores"}
                description={AccountsDescription}
                startOpen
                alignArrow="right"
              >
                {insightsData?.accounts && (
                  <HorizontalTableList
                    listItems={convertAccountsData(
                      insightsData.accounts,
                      provider
                    )}
                    productOffer={
                      refiEligible
                        ? {
                            component: <RefiCreditInsightsWidget />,
                            position: 0,
                          }
                        : undefined
                    }
                    tooltip={InsightsTooltip}
                  />
                )}
              </Accordion>
            </Card>
            <Card>
              <Accordion
                title={`Credit impairments`}
                dataCount={`(${
                  mergeArrays(
                    insightsData?.defaults || [],
                    insightsData?.judgements || [],
                    insightsData?.bankruptcies || []
                  ).length || 0
                })`}
                subtitle={"High impact to credit scores"}
                description={ImpairmentsDescription}
                startOpen
                alignArrow="right"
              >
                {insightsData?.judgements &&
                  insightsData.defaults &&
                  insightsData.bankruptcies && (
                    <HorizontalTableList
                      listItems={convertImpairmentData(
                        insightsData.defaults,
                        insightsData.judgements,
                        insightsData.bankruptcies
                      )}
                      tooltip={InsightsTooltip}
                    />
                  )}
              </Accordion>
            </Card>
            <Card>
              <Accordion
                title={`Credit age`}
                dataCount={`(${monthsToYears(
                  (insightsData?.ageOfFileResponse &&
                    insightsData.ageOfFileResponse.ageOfFileMonths) ||
                    0
                )})`}
                subtitle={"Moderate impact to credit scores"}
                description={AgeDescription}
                startOpen
                alignArrow="right"
              >
                {insightsData?.ageOfFileResponse && (
                  <HorizontalTableList
                    listItems={convertCreditAgeData(
                      insightsData.ageOfFileResponse
                    )}
                    tooltip={InsightsTooltip}
                  />
                )}
              </Accordion>
            </Card>
            <Card>
              <Accordion
                title={`Identities`}
                dataCount={`(${insightsData?.identities.length || 0})`}
                subtitle={"Low impact to credit scores"}
                description={IdentitiesDescription}
                startOpen
                alignArrow="right"
              >
                {insightsData?.identities && (
                  <HorizontalTableList
                    listItems={convertIdentitiesData(insightsData.identities)}
                    tooltip={InsightsTooltip}
                  />
                )}
              </Accordion>
            </Card>
            <Card>
              <Accordion
                title={`Employers`}
                dataCount={`(${insightsData?.employers.length || 0})`}
                subtitle={"Low impact to credit scores"}
                description={EmployersDescription}
                startOpen
                alignArrow="right"
              >
                {insightsData?.employers && (
                  <HorizontalTableList
                    listItems={convertEmployerData(insightsData.employers)}
                    tooltip={InsightsTooltip}
                  />
                )}
              </Accordion>
            </Card>
            <Card>
              <Accordion
                title={`Addresses`}
                dataCount={`(${insightsData?.addresses.length || 0})`}
                subtitle={"Low impact to credit scores"}
                description={AddressesDescription}
                startOpen
                alignArrow="right"
              >
                {insightsData?.addresses && (
                  <HorizontalTableList
                    listItems={convertAddressesData(insightsData.addresses)}
                    tooltip={InsightsTooltip}
                  />
                )}
              </Accordion>
            </Card>

            <div
              className={`${
                showOnScroll
                  ? /* istanbul ignore next */
                    "mobile-sticky-container show"
                  : "mobile-sticky-container"
              }`}
            >
              <SpotError type="accordion" provider={provider} />
            </div>
          </div>
        </div>

        <div className="suffix">
          <div className="sticky-container">
            <SpotError type="card" provider={provider} />
          </div>
        </div>
      </PageWrapperComponent>
    );
  }

  return (
    <section className="loading-wrapper">
      <Card>
        <SkeletonLoader lines={4} />
      </Card>
      <Card>
        <SkeletonLoader lines={4} />
      </Card>
      <Card>
        <SkeletonLoader lines={4} />
      </Card>
    </section>
  );
};

const InsightsTooltip = (
  <p>
    Is this wrong?
    <br />
    <a
      href={`${BRAND_WEBSITE}/help-categories/credit-scores`}
      rel="noopener noreferrer"
      target="_blank"
    >
      Find out how to report it to the bureaus.
    </a>
  </p>
);
