import React from "react";
import pageStyle from "./page-wrapper-layout.scss";

interface PageProps {
  size: "full" | "half" | "third" | "quarter";
}

export const PageWrapperComponent: React.FunctionComponent<PageProps> = ({
  size,
  children,
}) => {
  return (
    <div className={`page-wrapper ${size}`}>
      <style jsx>{pageStyle}</style>
      {children}
    </div>
  );
};
